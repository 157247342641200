import { useState, useEffect } from 'react'

function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T | ((val: T) => T)) => void, boolean] {
  const [storedValue, setStoredValue] = useState<T>(initialValue)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const readValue = () => {
      if (typeof window === 'undefined') {
        return initialValue
      }

      try {
        const item = window.localStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      } catch (error) {
        console.warn(`Error reading localStorage key “${key}”:`, error)
        return initialValue
      }
    }

    setStoredValue(readValue())
    setLoading(false)
  }, [key, initialValue])

  const setValue = (value: T | ((val: T) => T)) => {
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment does not support it.`,
      )
      return
    }

    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
      setStoredValue(valueToStore)
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error)
    }
  }

  return [storedValue, setValue, loading]
}

export default useLocalStorage
